import axios from 'axios';
import env from '../env';

export const AreaService = {

    async getAll(order_by, asc) {
        return axios.post(env.API + '/areas/all', {
            order_by: order_by,
            asc: asc
        });
    },

    async getNamePerPage(name, order_by, asc, page) {
        return axios.post(env.API + '/areas/all', {
            name: name,
            order_by: order_by,
            asc: asc,
            page: page,
        }, {headers: {'page-size': 20}});
    },

    async getAutocomplete(name){
        return axios.post(env.API + '/areas/autocomplete',{
            name:name,
        });
    },

    async postArea(name) {
        return axios.post(env.API + '/areas', {
            name: name
        })
    },

    async delAreaId(id) {
        return axios.delete(env.API + '/areas/' + id)
    },

    //no tines la api
    async updateAreaId(id, name) {
        return axios.post(env.API + '/areas',{
            id: id,
            name: name
        })
    }
}