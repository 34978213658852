import axios from 'axios';
import env from '../env';

export const FeatureService = {

    async getAll() {
        return axios.get(env.API + '/features/all');
    },

    async getAutocomplete(name, pos, category_id){
        return axios.post(env.API + '/features/autocomplete',{
            name: name,
            pos: pos,
            category_id: category_id
        })
    },

    async getAllperPage(name, order_by, asc, page){
        return axios.post(env.API + '/features/all',{
            name: name,
            order_by: order_by,
            asc: asc,
            page: page
        },{headers:{'page-size': 20}})
    },

    async postFeature(name, id, pos) {
        return axios.post(env.API + '/features', {
            name: name,
            category_id: id,
            pos: pos
        });
    },

    async getFeaturePos(category, pos){
        return axios.post(env.API + '/features/autocomplete',{
            category_id: category,
            pos: pos,
        });
    },

    async delFeatureId(id) {
        return axios.delete(env.API + '/features/' + id);
    },

    async updateFeaturePerId(id, name, category_id, pos){
        return axios.post(env.API + '/features',{
            id: id,
            name: name,
            category_id: category_id,
            pos: pos
        })
    }
}