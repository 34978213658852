import env from "../env";
import axios from "axios";

export const DivisionService = {

    async getAll() {
        return axios.get(env.API + '/divisions/all');
    },

    async getAutocomplete(name, area){
        return axios.post(env.API + '/divisions/autocomplete',{
            name: name,
            area_id: area,
        })
    },

    async getAllperPage(name, order_by, asc, page){
        return axios.post(env.API + '/divisions/all',{
            name: name,
            order_by: order_by,
            asc: asc,
            page: page
        },{headers:{'page-size': 20}})
    },

    async postDivision(nombre, id) {
        return axios.post(env.API + '/divisions', {
            name: nombre,
            area_id: id
        })
    },

    async delDivisionId(id) {
        return axios.delete(env.API + '/divisions/' + id)
    },

    async updateDivisionId(id, name, area_id){
        return axios.post(env.API + '/divisions',{
            id: id,
            name: name,
            area_id: area_id
        })
    },
}