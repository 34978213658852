import axios from 'axios';
import env from '../../env';

export const RetailsService = {

    async getAll() {
        return axios.post(env.API + '/retails/all');
    },

    async getPerCountry(country) {
        console.log(country)
        return axios.post(env.API + '/retails/all', {
            countries: [country]
        });
    },
}