import axios from 'axios';
import env from '../env';

export const BrandService = {

    async getAll() {
        return axios.get(env.API + '/brands/all');
    },
    
    async getAutocomplete(name){
        return axios.post(env.API + '/brands/autocomplete',{
            name: name
        })
    },

    async getAllperPage(name, order_by, asc, page){
        return axios.post(env.API + '/brands/all',{
            name: name,
            order_by: order_by,
            asc: asc,
            page: page
        },{headers:{'page-size': 20}})
    },

    async postBrand(name) {
        return axios.post(env.API + '/brands', {
            name: name
        })
    },

    async delBrandId(id) {
        return axios.delete(env.API + '/brands/' + id)
    },

    //no tines la api
    async updateBrandId(id, name) {
        return axios.post(env.API + '/brands',{
            id: id,
            name: name
        })
    }
}