import axios from 'axios';
import env from '../../env';

export const ProductsService = {

    async getAll() {
        return axios.post(env.API + '/products/all');
    },

    async getAllParams(retails, categories, countries, brands) {
        return axios.post(env.API + '/products/all', {
            retails: retails,
            categories: categories,
            countries: countries,
            brands: brands,
        },{headers:{'page-size': 50}})
    },

    async getAllParamsPage(retails, categories, countries, brands, pages) {
        return axios.post(env.API + '/products/all', {
            retails: retails,
            categories: categories,
            countries: countries,
            brands: brands,
            page: pages,
        },{headers:{'page-size': 50}})
    },

    async getPendingParamPage(retails, categories, countries, brands, pages, start_date, end_date){
        return axios.post(env.API + '/products/pending',{
            page: pages,
            retails: retails,
            categories: categories,
            countries: countries,
            brands: brands,
            start_date: start_date,
            end_date: end_date,
            last_seen_products: env.USE_TAXO_TABLE ? false : true,
        },{headers:{'page-size': 50}})
    },

    async getPending(country, retails, categories, brands, start_date, end_date) {
        return axios.post(env.API + '/products/pending', {
            countries: [country],
            retails: retails,
            categories: categories,
            brands: brands,
            start_date: start_date,
            end_date: end_date,
            last_seen_products: env.USE_TAXO_TABLE ? false : true,
        },{headers:{'page-size': 50}})
    },

    async getPendingCount(country, retails, categories, brands, start_date, end_date){
        return axios.post(env.API + '/products/pending/count', {
            countries: [country],
            retails: retails,
            categories: categories,
            brands: brands,
            start_date: start_date,
            end_date: end_date,
            last_seen_products: env.USE_TAXO_TABLE ? false : true,
        },{headers:{'page-size': 50}})
    },

    async getDescartadosCount(country, retails, categories){
        return axios.post(env.API + '/products/removed/count', {
            countries: [country],
            retails: retails,
            categories: categories
        },{headers:{'page-size': 50}})
    },

    async getDescartados(country, retails, categories){
        return axios.post(env.API + '/products/removed',{
            countries: [country],
            retails: retails,
            categories: categories,
        },{headers:{'page-size': 50}});
    },

    async getDescartadosParams(country, retails, categories, pages){
        return axios.post(env.API + '/products/removed',{
            countries: [country],
            retails: retails,
            categories: categories,
            page: pages,
        },{headers:{'page-size': 50}});
    },

    async postTaxonomizado(name, brand, md5, feature1, feature2, feature3, feature4, feature5, category) {
        return axios.post(env.API + '/products', {
            name: name,
            brand_id: brand,
            md5_link: md5,
            feature_1: feature1,
            feature_2: feature2,
            feature_3: feature3,
            feature_4: feature4,
            feature_5: feature5,
            category_id: category
        });
    },

    async postEditTaxonomizado(id, name, brand, md5, feature1, feature2, feature3, feature4, feature5, category) {
        return axios.post(env.API + '/products', {
            id: id,
            name: name,
            brand_id: brand,
            md5_link: md5,
            feature_1: feature1,
            feature_2: feature2,
            feature_3: feature3,
            feature_4: feature4,
            feature_5: feature5,
            category_id: category
        });
    },

    async softDelete(md5){
        return axios.delete(env.API + '/products/pending/'+md5);
    },

    async getNames(string, id){
        return axios.post(env.API + '/products/autocomplete',{
            name: string,
            brand_id: id
        })
    },

    async retrocederDadosByMd5(md5){
        return axios.delete(env.API + '/products/removed/'+ md5);
    },

    async comparative(obj){
        return axios.post(env.API + "/products/comparative",{
            date: obj.date,
            country: obj.country,
            retails: obj.retail,
            categories: [obj.categories],
            brands: obj.brands
        })
    },

    async historico(obj, limit = 100, offset = 0){
        return axios.post(env.API + "/products/history",{
            start_date: obj.date.desde,
            end_date: obj.date.hasta,
            country: obj.country,
            retails: obj.retail,
            categories: [obj.categories],
            brands: obj.brands,
            limit: limit,
            offset: offset,
            count: false
        })
    }
}