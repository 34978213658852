import axios from 'axios';
import env from '../../env';

export const CategoriaService = {

    async getAll() {
        return axios.post(env.API + '/retails/all');
    },

    async getPerCountry(country, retail) {
        if (retail.length) {
            return axios.post(env.API + '/old_categories/all', {
                countries: [country],
                retails: retail
            });
        } else {
            return axios.post(env.API + '/old_categories/all', {
                countries: [country],
                retails: [retail]
            });
        }

    },
}