import axios from 'axios';
import env from '../../env';

export const OldBrandService = {

    async getAutoComplete(retails, countries, categories, brand) {
        return axios.post(env.API + '/old_brands/autocomplete', {
            retails: retails,
            countries: countries,
            categories: categories,
            brand: brand
        });
    },
}