import env from "../env";
import axios from "axios";

export const CategoriaServiceABM = {

    async getAll() {
        return axios.get(env.API + '/categories/all');
    },

    async getAutocomplete(name, division){
        return axios.post(env.API + '/categories/autocomplete',{
            name: name,
            division_id: division
        });
    },

    async getAllperPage(name, order_by, asc, page){
        return axios.post(env.API + '/categories/all',{
            name: name,
            order_by: order_by,
            asc: asc,
            page: page
        },{headers:{'page-size': 20}})
    },

    async postCategoria(nombre, id) {
        return axios.post(env.API + '/categories', {
            name: nombre,
            division_id: id
        })
    },

    async delCategoriaId(id) {
        return axios.delete(env.API + '/categories/' + id)
    },

    //no tines la api
    async updateCategoriaId(id, name, division_id){
        return axios.post(env.API + '/categories',{
            id: id,
            name: name,
            division_id: division_id
        })
    },
}
